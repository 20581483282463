// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDmNqpyl3JYak4HtPEcz5RsRVoV6eZIubM",
    authDomain: "flowframe-dc5ab.firebaseapp.com",
    projectId: "flowframe-dc5ab",
    storageBucket: "flowframe-dc5ab.firebasestorage.app",
    messagingSenderId: "199237620172",
    appId: "1:199237620172:web:918007d1824a84baf98d69",
    measurementId: "G-E7RSTJ9G15"
  };

  // Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export { app };